<template>

    <template v-if="isLoading">...loading</template>

    <Empty v-else-if="!allowedAccess" illustration="contracts">
        <template #title>Ingen adgang</template>
        <template #description>{{ `Du har ikke adgang til at se denne ${ isAddition ? 'aftale' : 'kontrakt' }` }}</template>
    </Empty>

    <Empty v-else-if="status === 'ACCEPTED'" illustration="contracts">
        <template #title>{{ isAddition ? 'Aftale godkendt' : 'Kontrakt godkendt' }}</template>
        <template #description>{{ isAddition ? 'Aftalen er godkendt og begge parter har fået besked.' : 'Kontrakten er godkendt og begge parter har fået besked.' }}</template>
    </Empty>

    <Empty v-else-if="status === 'REJECTED'" illustration="contracts">
        <template #title>{{ isAddition ? 'Aftale afvist' : 'Kontrakt afvist' }}</template>
        <template #description>{{ isAddition ? 'Aftalen er afvist og begge parter har fået besked.' : 'Kontrakten er afvist og begge parter har fået besked.' }}</template>
    </Empty>

    <template v-else>
        <Main :show-title="false" style="grid-column: span 2;">
            <Form ref="form" @submit="accept" :message="message">
                <template v-if="files.length">
                    <article v-for="[index, file] in files" :key="index">
                        <h3>{{ file.name }}</h3>
                        <div class="file">
                            <div :id="index"></div>
                        </div>
                        <Checkbox :required="true" @change="checkValidation">Jeg har læst og accepterer dette bilag</Checkbox>
                    </article>
                </template>
            </Form>
        </Main>
        <aside>
            <div class="aside scroll card" v-if="isAddition">
                <div class="content">
                    <h1>Tillægsaftale</h1>
                    <p>Denne aftale er et tillæg til den allerede accepterede kontrakt der er indgået imellem parterne. Når dette tillæg/fradrag er accepteret, modtager begge parter en kvittering på mail med bilag indeholdt.</p>
                    <p v-if="files.length"><strong>OBS:</strong> Alle bilag skal afkrydses før mulig accept.</p>
                    <h2>Aftaleparter</h2>
                    <p class="columns">
                        <span>
                            <small>Forbrugeren</small><br>
                            <strong>
                                {{ contract.contract.builder.name }}
                            </strong>
                        </span>
                        <span class="align-left">
                            <small>Entreprenøren</small><br>
                            <strong>
                                {{ contract.contract.company.name }}<br>
                                Att. {{ contract.contract.contact.name }}
                            </strong>
                        </span>
                    </p>
                    <h2>Aftalens indhold</h2>
                    <p>
                        <small>Beskrivelse</small><br>
                        <strong>{{ contract.description }}</strong>
                    </p>
                    <p class="columns">
                        <span>
                            <small>Udførelsessted</small><br>
                            <strong>{{ contract.contract.project.address }}</strong>
                        </span>
                        <span class="align-left">
                            <small>Entreprise</small><br>
                            <strong>{{ contract.contract.agreement.subcontract }}</strong>
                        </span>
                    </p>
                    <p class="columns">
                        <span>
                            <small>Tidsmæssig konsekvens</small><br>
                            <strong>{{ contract.days }} dage</strong>
                        </span>
                        <span class="align-left">
                            <small>{{ getPaymentTypeLabel('fixed') }}</small><br>
                            <strong>{{ formatNumberToPrice(contract.price) }} inkl. moms</strong>
                        </span>
                    </p>
                </div>
                <footer>
                    <ActionButton label="Accepter aftale" icon="check" :small="true" @click="$refs.form.submit()" :disabled="!valid || processing"/>
                    <ActionButton label="Afvis aftale" icon="trash" :secondary="true" :small="true" :red="true" @click="reject" type="button"/>
                </footer>
            </div>
            <div class="aside scroll card" v-else>
                <div class="content">
                    <h1>Kontraktoversigt</h1>
                    <p>Dette er en kontrakt imellem de to aftaleparter der står oplyst nedenfor. Når denne kontrakt er accepteret, modtager begge parter en kvittering på mail med indholdende bilag.</p>
                    <p><strong>OBS:</strong> Alle bilag skal afkrydses før mulig accept.</p>
                    <h2>Aftaleparter</h2>
                    <p class="columns">
                        <span>
                            <small>Forbrugeren</small><br>
                            <strong>
                                {{ contract.builder.name }}
                            </strong>
                        </span>
                        <span class="align-left">
                            <small>Entreprenøren</small><br>
                            <strong>
                                {{ contract.company.name }}<br>
                                Att. {{ contract.contact.name }}
                            </strong>
                        </span>
                    </p>
                    <h2>Aftalens indhold</h2>
                    <p>
                        <small>Beskrivelse</small><br>
                        <strong>{{ contract.agreement.description }}</strong>
                    </p>
                    <p>
                        <small>Udførelsessted</small><br>
                        <strong>{{ contract.project.address }}</strong>
                    </p>
                    <p class="columns">
                        <span>
                            <small>Entreprise</small><br>
                            <strong>{{ contract.agreement.subcontract }}</strong>
                        </span>
                        <span class="align-left">
                            <small>{{ getPaymentTypeLabel(contract.agreement.payment.type) }}</small><br>
                            <strong>{{ formatNumberToPrice(contract.agreement.payment.price) }} inkl. moms</strong>
                        </span>
                    </p>
                    <p class="columns">
                        <span>
                            <small>Tidligst opstart</small><br>
                            <strong>{{ formatDateToFnsDate(contract.project.from.toDate()) }}</strong>
                        </span>
                        <span class="align-left">
                            <small>Senest aflevering</small><br>
                            <strong>{{ formatDateToFnsDate(contract.project.to.toDate()) }}</strong>
                        </span>
                    </p>
                </div>
                <footer>
                    <ActionButton label="Accepter aftale" icon="check" :small="true" @click="$refs.form.submit()" :disabled="!valid || processing"/>
                    <ActionButton label="Afvis aftale" icon="trash" :secondary="true" :small="true" :red="true" @click="reject" type="button"/>
                </footer>
            </div>
        </aside>
    </template>

</template>

<script>
import Main from '@/components/Main.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { useRoute } from 'vue-router';
import { db, downloadFile, firebase } from '@/utils/firebase';
import Empty from '@/components/Empty.vue';
import methods from '@/utils/methods';
import Checkbox from '@/components/inputs/Checkbox.vue';
import Form from '@/components/Form.vue';
import { ref } from 'vue';
import template from '@/utils/template';

export default {
    name: 'Accept',
    components: {
        Main,
        ActionButton,
        Empty,
        Checkbox,
        Form,
    },
    setup() {
        const { id, token } = useRoute().params;
        const contract = ref(null);
        const files = ref([]);
        const isLoading = ref(true);
        const isAddition = ref(false);
        const valid = ref(false);

        const allowedAccess = ref(false);
        const status = ref(null);

        const loadScript = async () => {
            const allFiles = contract.value?.agreement?.files || contract.value.file;
            if (allFiles) {
                files.value = Object.entries(isAddition.value ? { file: { ...allFiles } } : allFiles);
                files.value = files.value.filter((file) => file[1] !== null);
            } else {
                valid.value = true;
            }

            const externalScript = document.createElement('script');
            externalScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js');
            document.head.appendChild(externalScript);

            document.addEventListener('adobe_dc_view_sdk.ready', () => {
                files.value.forEach(async (data) => {
                    const [index, file] = data;

                    const signedUrl = await firebase.functions().httpsCallable('generateReadSignedUrl');
                    const url = await signedUrl({ reference: file.path });

                    if (file.path.indexOf('pdf') > 0) {
                        new AdobeDC.View({
                            clientId: window.location.hostname === 'localhost' ? '02610b34ec064b2c95287d3825eac123' : '91ad95d43272415db8984619a6d36b53',
                            divId: index,
                        }).previewFile({
                            content: { location: { url: url.data } },
                            metaData: { fileName: file.name },
                        }, { embedMode: 'SIZED_CONTAINER', showDownloadPDF: false, showPrintPDF: false });
                    } else {
                        const img = document.createElement('img');
                        img.src = url.data;
                        img.addEventListener('click', async () => {
                            const permalink = await downloadFile(file.path);
                            const anchor = document.createElement('a');
                            anchor.href = await permalink;
                            anchor.target = '_blank';
                            anchor.download = '';
                            anchor.click();
                        });
                        document.getElementById(index).appendChild(img);
                    }
                });
            });

            isLoading.value = false;
        };

        (async () => {
            try {
                let querySnapshot = await db.collectionGroup('contracts').where('token', '==', token).limit(1).get();
                if (!querySnapshot.size) {
                    querySnapshot = await db.collectionGroup('additions').where('token', '==', token).limit(1).get();
                    const snapshot = querySnapshot.docs[0];
                    const parent = await snapshot.ref.parent.parent.get();
                    contract.value = { id: snapshot.id, ref: snapshot.ref, ...snapshot.data(), contract: { id: parent.id, ...parent.data() } };
                    isAddition.value = true;
                } else {
                    const snapshot = querySnapshot.docs[0];
                    contract.value = { id: snapshot.id, ref: snapshot.ref, ...snapshot.data() };
                }

                status.value = contract.value.status.state;
                allowedAccess.value = id === contract.value.id || status.value === 'SENT';

                if (allowedAccess.value && status.value === 'SENT') loadScript();
                else isLoading.value = false;
            } catch {
                isLoading.value = false;
            }
        })();

        return { isLoading, allowedAccess, status, contract, files, isAddition, valid };
    },
    data() {
        return {
            message: null,
            processing: false,
        };
    },
    mixins: [methods],
    methods: {
        checkValidation() {
            this.valid = this.$refs.form.isFormFieldsValid();
        },
        async accept() {
            this.processing = true;

            const attachments = await Promise.all(this.files.map(async (file) => {
                const pdf = file[1];
                const signedUrl = await firebase.functions().httpsCallable('generateReadSignedUrl');
                const path = await signedUrl({ reference: pdf.path });
                return { filename: pdf.name, path: path.data };
            }));

            const companyMailData = this.isAddition ? {
                subject: 'Aftalen er nu accepteret',
                message: `Aftalen ifm. tillæg eller fradrag til den oprindelige aftale er nu underskrevet af ${this.contract.contract.contact.name}, ${this.contract.contract.company.name} og ${this.contract.contract.builder.name}.`,
                agreement: {
                    description: this.contract.description,
                    address: this.contract.contract.project.address,
                    subcontract: this.contract.contract.agreement.subcontract,
                    payment: {
                        type: this.getPaymentTypeLabel('fixed'),
                        price: `${this.formatNumberToPrice(this.contract.price)} inkl. moms`,
                    },
                    days: `${ this.contract.days } dage`,
                },
                attachments,
            } : {
                subject: 'Kontrakten er nu accepteret',
                message: `Kontrakten er underskrevet af ${this.contract.contact.name}, ${this.contract.company.name} og ${this.contract.builder.name}.`,
                agreement: {
                    description: this.contract.agreement.description,
                    address: this.contract.project.address,
                    subcontract: this.contract.agreement.subcontract,
                    payment: {
                        type: this.getPaymentTypeLabel(this.contract.agreement.payment.type),
                        price: `${this.formatNumberToPrice(this.contract.agreement.payment.price)} inkl. moms`,
                    },
                    from: this.formatDateToFnsDate(this.contract.project.from.toDate()),
                    to: this.formatDateToFnsDate(this.contract.project.to.toDate()),
                },
                attachments,
            };

            const userMailData = this.isAddition ? {
                subject: 'Din aftale er nu accepteret',
                message: `Din aftale er underskrevet af ${this.contract.contract.contact.name}, ${this.contract.contract.company.name}.`,
                button: {
                    link: `https://app.selvbyggerplus.dk/contracts/${this.contract.contract.id}/additions/${this.contract.id}`,
                    text: 'Vis aftale i Selvbygger+',
                },
            } : {
                subject: 'Din kontrakt er nu accepteret',
                message: `Din kontrakt er underskrevet af ${this.contract.contact.name}, ${this.contract.company.name}.`,
                button: {
                    link: `https://app.selvbyggerplus.dk/contracts/${this.contract.id}`,
                    text: 'Vis kontrakt i Selvbygger+',
                },
            };

            await this.send(companyMailData, userMailData, 'ACCEPTED');
        },
        reject() {
            this.processing = true;

            const companyMailData = this.isAddition ? {
                subject: 'Aftalen er desværre afvist',
                message: `Hvis det var en fejl, kan du bede bygherren (${this.contract.contract.builder.name}) om at fremsende aftalen igen.`,
            } : {
                subject: 'Kontrakten er desværre afvist',
                message: `Hvis det var en fejl, kan du bede bygherren (${this.contract.builder.name}) om at fremsende kontrakten igen.`,
            };

            const userMailData = this.isAddition ? {
                subject: 'Din tillægsaftale er desværre afvist',
                message: `${this.contract.contract.contact.name}, ${this.contract.contract.company.name} har desværre afvist din kontrakt.<br><br>Hvis det var en fejl, er Entreprenøren blevet bedt om at kontakte dig, så du kan fremsende kontrakten igen.`,
                button: {
                    link: `https://app.selvbyggerplus.dk/contracts/${this.contract.contract.id}/additions/${this.contract.id}`,
                    text: 'Vis kontrakt i Selvbygger+',
                },
            } : {
                subject: 'Din kontrakt er desværre afvist',
                message: `${this.contract.contact.name}, ${this.contract.company.name} har desværre afvist din kontrakt.<br><br>Hvis det var en fejl, er Entreprenøren blevet bedt om at kontakte dig, så du kan fremsende kontrakten igen.`,
                button: {
                    link: `https://app.selvbyggerplus.dk/contracts/${this.contract.id}`,
                    text: 'Vis kontrakt i Selvbygger+',
                },
            };

            this.send(companyMailData, userMailData, 'REJECTED');
        },
        async send(companyMailData, userMailData, state) {
            try {
                const attachments = companyMailData.attachments || [];

                const companyMail = {
                    to: this.isAddition ? this.contract.contract.contact.email : this.contract.contact.email,
                    message: {
                        subject: companyMailData.subject,
                        html: template(companyMailData),
                        attachments,
                    },
                    status: state,
                };

                const userMail = {
                    to: this.isAddition ? this.contract.contract.builder.email : this.contract.builder.email,
                    message: {
                        subject: userMailData.subject,
                        html: template(userMailData),
                        attachments,
                    },
                    status: state,
                    updateAddition: true,
                };

                await this.contract.ref.collection('mails').add(companyMail);
                await this.contract.ref.collection('mails').add(userMail);
                this.status = state;
            } catch (err) {
                this.processing = false;
                console.log(err);
                this.message = { text: err, error: true };
            }
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

body.accept-template {
    overflow: auto;

    @media @laptop-max-screen {
        position: static;
        height: auto;
        display: block;
    }

    main {
        border-radius: 0;
        @media @laptop-max-screen {
            padding-bottom: var(--container-padding);
        }
    }

    form {
        max-width: none;

        @media @laptop-screen {
            display: grid;
            grid-gap: 35px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-flow: row;
        }

        article {
            width: 100%;
        }

        div.file {
            box-shadow: 0 10px 20px rgba(var(--grey-color), 30%);
            position: relative;
            height: 0;
            padding-bottom: 141%;
            margin-bottom: 2rem;

            > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    cursor: pointer;
                }
            }
        }
    }

    aside {
        background: rgb(var(--light-grey-color));
        box-sizing: border-box;

        @media @laptop-screen {
            width: 440px;
            margin: 20px;
            border-radius: var(--large-border-radius);
        }

        .scroll.card footer button {
            width: calc(50% - 8px);

            &[disabled] {
                pointer-events: none;
                opacity: 0.3;
            }

            &.red {
                border-color: rgb(var(--red-status-text-color));
            }
        }
    }
}
</style>
